














import { Component, Prop, Vue } from 'vue-property-decorator';
import FAQMd from '@/docs/FAQ.md';
import VueMarkdown from 'vue-markdown';

@Component({
  components: {
    VueMarkdown,
  }
})
export default class FAQ extends Vue {
  get FAQContent(): string {
    return FAQMd;
  }
}
